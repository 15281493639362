@import "global/constants";

.settings {
    @include page;

    .nav-tabs {
        margin-bottom: 16px;

        a.tab {
            color: $darkGrey;
            font-size: 12px;
            line-height: 16px;

            .tab-icon {
                color: $grey;
                margin-right: 5px;
            }
        }

        a.tab.active {
            color: $darkest-gray;
            border-radius: 5px 5px 0px 0px;
            border-bottom-color: $background-light;
            background: transparent;

            .tab-icon {
                color: $darkest-gray;
            }
        }
    }

    .tab-content {
        width: 100%;
    }
}

.modal-open .settings {
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
}
