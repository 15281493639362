@import "global/constants";

.notification-toggles {
    width: 460px;

    .header > * {
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;

        .info-icon {
            margin-left: 4px;
            color: $grey;
            cursor: pointer;

            &:hover {
                color: $background-dark;
            }
        }

        .text-tooltip {
            position: absolute;
            z-index: 1;
            width: 201px;
            left: 13px;
            top: 30px;
        }
    }

    .recipient-row {
        margin-top: 12px;

        .recipient {
            font-size: 12px;
            line-height: 16px;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;

            .division {
                color: $grey;
                font-weight: bold;
                margin-left: 5px;
            }
        }
    }
}
