@import "global/constants.scss";
.simple-container {
    position: relative;
    background: $white;
    border: 1px solid $light-border;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 32px;

    &.titled {

        .title {
            font-style: normal;
            font-weight: bold;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: .08em;
            color: $white;
            margin-bottom: 16px;
            position: absolute;
            top: -8px;
            left: 20px;
            padding: 5px 29px 5px 29px;
        }

        .active {
            background-color: $background-dark;
        }
        .inactive {
            background-color: $darkGrey;
        }
        .centered {
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            width: fit-content;
        }
    }
}