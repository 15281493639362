@import "global/constants.scss";


/* Tooltip container */
.darkColor {
  color: $background-dark;
}
.lightColor {
  color: $grey;
}
.tooltipText {
  position: absolute;
  right: 12px;
  z-index: 50;
  @media screen and (max-width: 1200px), (min-width: 1401px) {
    top: 0px;
  }

  .info-icon {
    width: 16px;
    height: 18px;
    cursor: pointer;
  }

  .tooltipDescription {
    width: 180px;
    top: 100%;
    left: 50%; 
    background-color: $background-dark;
    color: $white;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    font-size: 12px;
    /* Position the tooltip text */
    position: absolute;
    z-index: 2;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding: 8px;
  }
  .center {    
    margin-top: 10px;
    margin-left: -90px; // 95px; /* Use half of the width (120/2 = 60), to center the tooltip */
  }
  .right {
    margin-top: 10px;
    margin-left: -157px; // 165px; /* Use half of the width (120/2 = 60), to center the tooltip */
  }
  .left {
    margin-top: 10px;
    margin-left: 0px; /* Use half of the width (120/2 = 60), to center the tooltip */
  }

  .center::after {
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
  }
  .right::after {
    bottom: 100%;  /* At the top of the tooltip */
    left: 90%;
    margin-left: -15px;
    border-width: 10px;
  }
  .left::after {
    bottom: 100%;  /* At the top of the tooltip */
    margin-left: -10%;
    border-width: 10px;
    left: 100%;
  }
  .tooltipDescription::after {
    content: " ";
    position: absolute;
    border-style: solid;
    border-color: transparent transparent $background-dark transparent;
  }
}


.fleet-tooltip {
  position: absolute;
  left: 15;
  left: 250px;
  top: 20px;
  z-index: 51;
  .tooltipDescription {
    left: 10px;
  }
  height: fit-content;
  width: fit-content;
}
