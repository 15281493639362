@import "global/constants";

.map {
    border-radius: 4px;

    &.disable-layers-control {
        .leaflet-control-layers {
            display: none;
        }
    }

    .leaflet-control-container {
        .leaflet-center {
            left: 50%;
            transform: translate(-50%, 0%);
        }

        .leaflet-middle {
            top: 50%;
            position: absolute;
            z-index: 1000;
            pointer-events: none;
            transform: translate(0%, -50%);
        }

        .leaflet-center.leaflet-middle {
            transform: translate(-50%, -50%);
        }

        .leaflet-left {
            .leaflet-control {
                margin-left: 16px;
            }
        }

        .leaflet-top {
            .leaflet-control {
                margin-top: 16px;
            }
        }

        .leaflet-right {
            .leaflet-control:not(.leaflet-control-attribution) {
                margin-right: 16px;
            }
        }

        .leaflet-bottom {
            .leaflet-control:not(.leaflet-control-attribution) {
                margin-bottom: 5px;
            }
        }

        .leaflet-control-zoom {
            border: 1px solid $grey;
            box-shadow: 0 8px 8px -6px rgba($darkGrey, 0.15);

            .leaflet-control-zoom-in,
            .leaflet-control-zoom-out {
                line-height: 36px;
                width: 40px;
                height: 40px;

                &.leaflet-disabled:hover {
                    color: $circle-background;
                }

                &:active {
                    color: initial;
                }

                &:hover {
                    text-decoration: none;
                    background-color: $background-light;
                    color: initial;
                }
            }

            .leaflet-control-zoom-in {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            .leaflet-control-zoom-out {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    .custom-marker-cluster {
        background-color: rgba($reefer-primary-100, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        .middle-circle {
            width: 36px;
            height: 36px;
            background-color: rgba($reefer-primary-100, 0.6);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            .inner-circle {
                width: 32px;
                height: 32px;
                background-color: $reefer-primary-100;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: $white;
                font-size: 12px;
                font-weight: 700;
            }
        }
    }

    .custom-popup {
        .leaflet-popup-content-wrapper {
            padding: 12px;
            border: 1px solid $grey;
            border-radius: 4px;
            box-shadow: 0 8px 8px -6px rgba($darkGrey, 0.15);

            .leaflet-popup-content {
                color: $darkest-gray;
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                margin: 0;
            }
        }

        .leaflet-popup-tip-container {
            .leaflet-popup-tip {
                border: 1px solid $grey;
                box-shadow: none;
            }
        }
    }
}
