// Sizes

$nav-bar-width: 60px;

// Colors

$white: white;
$black: black;
$background-light: #f4f5fa;
$background-dark: #172437;
$background-image-dark: linear-gradient(
  180deg, 
  $background-dark 6.67%, 
  #030D1D 100%
);
$circle-background: #bbb;
$icon-background: #F4F5FA;
$notification-background-light: #FCFEFE;
$darkest-gray: #030d1d;
$navbar-background: #2e486e;
$light-border: #E2E5E9;
$table-header-color: #E8EBF3;
$header-border-color: #D0D2DD;
$light-input-background-color: #FDF6F6;
$disabled-snooze-color: #DCDEEA;
$green: #19bd8c;
$red: #d3424e;
$grey: #d0d2dd;
$darkGrey: #828A95;
$lightGrey: #E3E7EB;
$orange: #F27C46;
$light-orange: #FFE9DD;
$yellow: #F8B60D;
$light-yellow: #FFF6C9;
$blue: #289AC2;
$light-blue: #EEF7FA;

$blue-team: #289AC2;
$red-team: #D3424E;
$green-team: #19BD8C;
$orange-team: #F27C46;
$yellow-team: #F2C94C;
$purple-team: #BB6BD9;
$black-team: #030D1D;
$grey-team: #828A95;
$white-team: #fff;
$no-team: #F554A1;

// New Shipex color palette

$reefer-primary-100: #3C5976;
$reefer-success-400: #2BA37F;
$reefer-warning-200: #FEF5F0;
$reefer-warning-300: #F9C5AC;
$reefer-warning-400: #F2C94C;
$reefer-warning-500: #F27C46;

:export {
  blueTeam: $blue-team;
  redTeam: $red-team;
  greenTeam: $green-team;
  orangeTeam: $orange-team;
  yellowTeam: $yellow-team;
  purpleTeam: $purple-team;
  blackTeam: $black-team;
  greyTeam: $grey-team;
  whiteTeam: $white-team;
  unknownTeam: $grey;
  disabledColor: $grey;
  noTeam: $no-team;
}

// Mixins

.link {
    color: $blue;
    text-decoration: underline;
    cursor: pointer;

    &:hover, &:active, &:focus {
        color: $blue;
        text-decoration: underline;
    }
}

.no-style-link {
    color: $darkest-gray;
    text-decoration: none;
    cursor: pointer;

    &:hover, &:active, &:focus {
        color: $darkest-gray;
        text-decoration: none;
    }
}

.greenColorText {
  color: $green;
}

.redColorText {
  color: $red;
}

.redColorTextHover:hover {
    color: $red;
}

.greyColorText {
  color: $darkGrey;
}

.orangeColorText {
  color: $orange;
}

.orangeColorTextHover:hover {
    color: $orange;
}

.blueColorText {
  color: $blue;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.centerAlign {
  display: flex;
  align-items: center;
}

@mixin background-dark {
  background-color: $background-dark;
  background-image: $background-image-dark;
  color: $white;
}

@mixin page {
    .page-title {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 47px;
        margin: 24px 0;
        color: #030D1D;
    }
}

.minSortWidth {
  min-width: 100px;
}
svg {
  display: block;
}
