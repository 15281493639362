@import "global/constants";

.graph-container {
    position: relative;
    overflow: visible;

    .content {
        position: relative;
        //z-index: 1;
    }

    .card-link {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .hidden {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        visibility: hidden;
    }

    .graph {
        position: absolute;
        user-select: none;
        z-index: 0;
        left: -1px;
        right: -1px;
        bottom: 0;
    }

    &.titled {

        .title {
            color: $background-dark;
            background: none;
            padding: unset;
            position: static;
            margin-left: 20px;
        }

        @media (min-width: 1350px) {
          .title {
            text-align: left;
          }
        }
    }
}

