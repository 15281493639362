@import "global/constants.scss";

.team-selection {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    height: 60px;
    margin: 0;
    padding: 0;
    font-size: 14px;
    
    &.hidden {
        display: none;
    }

    .info-label {
        margin-right: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
    }
    .picker {
        position: relative;
        display: block;

        .title {
            display: block;
            text-transform: uppercase;
            margin-right: 12px;
            font-weight: 600;
            color: black;
            cursor: pointer;

            .multiple-teams {
                display: flex;
                vertical-align: middle;
                align-items: center;
            }

            svg {
                margin-right: 5px;
                margin-left: 5px;
            }
        }

        .drop-menu {
            position: absolute;
            min-width: 165px;
            z-index: 100;
            top: 41px;
            right: 0;
            padding: 4px;
            margin: 0;

            background: white;
            border: 1px solid $light-border;
            box-sizing: border-box;
            border-radius: 4px;

            &.shown {
                display: block;
            }

            &.hidden {
                display: none;
            }

            .item {
                display: flex;
                vertical-align: middle;
                align-items: center;
                position: relative;
                line-height: 0;
                padding: 8px;
                margin: 0;
                height: 32px;
                box-sizing: border-box;
                font-size: 12px;
                font-weight: 600;
                cursor: pointer;

                input[type=checkbox] { 
                    display:none; 
                    cursor: pointer;

                    + label:before {
                        font-family: FontAwesome;
                        display: inline-block;
                        background: #F4F5FA;
                        content: "\f0c8";
                        color: $grey;
                        letter-spacing: 10px;
                    }

                    &:checked + label:before {
                        content: "\f14a"; 
                        color: #2aa1c0;
                        letter-spacing: 10px;
                    }
                } 
                 
                label {
                    cursor: pointer;
                    margin-left: 8px;
                    margin-bottom: 0px;
                }

                &:hover {
                    background-color: $background-light;
                }

                &.disable-all {
                    &:hover {
                        background-color: unset;
                    }
                    
                    opacity: 0.25;
                }
            }

            .multiple-item-icon {
                display: inline;
            }
        }
    }
}
