@import "global/constants";

.location-tooltip {
  .arrow-box {
    box-shadow: 0 8px 24px -8px rgba(3, 13, 29, 0.15);
    position: relative;
    background: $white;
    border: 1px solid $light-border;
    width: 230px;
    border-radius: 4px;
    padding: 10px;

    .location-title {
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: $light-border;
      margin-bottom: 5px;
      margin-top: -5px;
    }

    .info-label {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: $darkest-gray;
      margin-right: 5px;
    }

    .info-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $darkest-gray;
    }
  }

  .arrow-box:after, .arrow-box:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .arrow-box:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: $white;
    border-width: 10px;
    margin-top: -10px;
  }

  .arrow-box:before {
    border-color: rgba(226, 229, 233, 0);
    border-right-color: $light-border;
    border-width: 11px;
    margin-top: -11px;
  }
}
