.reefer-remote-control-modal {

    .modal-container {
        margin-bottom: 0;
        padding: 30px 24px 24px 24px;

        .message-card {
            margin-bottom: 12px;
        }

        .title {
            text-transform: uppercase;
        }

        .command-select {
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 24px;
        }

        .label {
            display: block;

            &.command-label {
                font-style: normal;
                font-weight: bold;
                font-size: 15px;
                line-height: 20px;
            }

            &.input-label {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                margin-top: 12px;
            }
        }

        .input {
            background: #FFFFFF;
            border: 1px solid #D0D2DD;
            box-sizing: border-box;
            border-radius: 4px;
            width: 100%;
            padding: 6px 12px;
        }

        .unit-mode-picker {
            margin-top: 12px;
            font-size: 12px;

            label {
                margin-right: 24px;
            }

            input {
                margin-right: 4px;
            }
        }

        .set-point-input {

            +.message-card {
                margin-top: 4px;
            }
        }

        .buttons {
            margin-top: 20px;
            text-align: right;

            .cancel-button,
            .submit-button {
                display: inline;
                height: 32px;
                width: 80px;
                text-transform: uppercase;
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 14px;
                padding: 0;

                &:disabled {
                    background: #D0D2DD;
                    color: #828A95;
                }
            }

            .cancel-button {
                margin-right: 10px;
                color: #828A95;
            }
        }

        .loader-spinner {
            width: fit-content;
            margin: 0 auto;
        }
    }
}
