@import "global/constants";

.ant-picker.date-picker {
    font-size: 12px;
    line-height: 16px;
    border: 1px solid $grey;
    border-radius: 4px;
    padding: 0 12px 0 0;

    &:hover {
        border: 1px solid $grey;
    }

    &.ant-picker-focused {
        box-shadow: none;
    }

    .ant-picker-suffix {
        margin-left: 0;
    }

    input {
        font-size: 12px;
        line-height: 16px;
        padding: 8px;

        &::placeholder {
            color: $darkGrey;
            font-weight: 500;
        }
    }
}
