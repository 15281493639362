.trailer-location {
    .trailer-map-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        row-gap: 8px;
        margin-top: 20px;
        margin-bottom: 16px;

        .message-card {
            line-height: 16px;
            height: fit-content;
            margin-right: 8px;
        }

        .trailers-pill-container {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            margin-left: auto;
            padding-top: 6px;
            padding-bottom: 4px;
        }
    }
}
