@import "global/constants";

.header {
    background-color: $white;

    .search {
        position: relative;
        height: 60px;
    }

    .user-settings {
        position: relative;
        height: 60px;
    }
}
