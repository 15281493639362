@import "global/constants";

.data-card {
    height: 120px;
    padding-top: 16px;
    border-color: $white;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
        border-color: $blue;
    }

    &.simple-container {
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 16px;

        .content {
            .container-title {
                display: flex;
                justify-content: space-between;

                .title {
                    padding: 4px 22px 0 16px;
                    margin-bottom: 0;
                }

                .tooltip-container {
                    position: relative;
                    display: inline-block;
                    cursor: pointer;
                }
            }
        }

        &.titled-card {
            .title {
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 14px;
                letter-spacing: .08em;
                text-transform: uppercase
            }
        }
    }

    .status-data {
        .current-active {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            padding: 0 16px 8px 16px;

            .icon-box {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                width: 38px;
                height: 38px;
                margin-right: 8px;
                border-radius: 8px;
            }
        }

        .status-data {
            .current-active {
                cursor: default;
                color: $darkGrey;

                .icon-box {
                    background-color: $icon-background;
                }
            }
        }
    }
}

.disabled-box {
    cursor: default;

    .status-data {
        .current-active {
            cursor: default;
            color: $darkGrey;

            .icon-box {
                background-color: $icon-background;
            }
        }
    }

    &:hover {
        border-color: $light-border;
        box-shadow: none;
    }
}

.blue {
    .status-data {
        .current-active {
            color: $blue;

            .icon-box {
                background-color: $light-blue;
            }
        }
    }
}

.yellow {
    .status-data {
        .current-active {
            color: $yellow;

            .icon-box {
                background-color: $light-yellow;
            }
        }
    }
}

.orange {
    .status-data {
        .current-active {
            color: $orange;

            .icon-box {
                background-color: $light-orange;
            }
        }
    }
}
