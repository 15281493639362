@import "global/constants";

.modal {
    .add-drop-yard-modal {
        .modal-content {
            width: 300px;
            margin: auto;

            .modal-container {
                margin-bottom: 0;
                border: none;
                padding-bottom: 88px;

                .company-label {
                    display: inline-block;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    margin-bottom: 4px;
                    margin-top: 26px;
                }

                .select-company__control {
                    height: 32px;
                    min-height: 32px;

                    .select-company__value-container {
                        height: 32px;

                        .select-company__single-value, .select-company__placeholder, .css-b8ldur-Input {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }

                    .select-company__indicators {
                        height: 32px;

                        .select-company__indicator-separator {
                            display: none;
                        }
                    }
                }

                .company-option {
                    font-size: 12px;

                    .company-name {
                        font-size: 8px;
                        margin-left: 4px;
                    }
                }

                .buttons {
                    position: absolute;
                    bottom: 20px;
                    right: 20px;

                    .cancel-button, .add-button {
                        display: inline;
                        height: 36px;
                        width: 106px;
                        text-transform: uppercase;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 14px;
                        padding: 0;

                        &:disabled {
                            color: $white;
                        }
                    }

                    .cancel-button {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
