@import "global/constants";

.notification-description {
    width: 448px;
    padding: 24px 0 24px 24px;
    font-size: 12px;
    line-height: 16px;

    @media screen and (max-width: 1142px) {
        padding: 24px 0 0 0;
    }

    .rule-title {
        font-weight: bold;
        margin-right: 4px;
        margin-bottom: 2px;
    }

    .resolved-title {
        margin: 16px 0 0 0;
    }

    .rule-description {
        margin: 6px 0 0 0;
    }
}
