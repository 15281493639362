@import "global/constants";

.select {
    font-size: 12px;
    line-height: 16px;
    min-width: 100px;

    .select__control {
        min-height: unset;
        border: 1px solid $grey;
        border-radius: 4px;
        box-shadow: none;
        cursor: pointer;

        &:hover {
            border-color: $grey;
        }

        .select__placeholder {
            color: $darkGrey;
            font-weight: 500;
        }

        .select__value-container {
            font-weight: 600;
            padding-right: 2px;
            flex-wrap: nowrap;
            white-space: nowrap;
        }

        .select__indicator {
            padding: 7px 7px 7px 0;

            &.select__clear-indicator {
                display: none;
            }

            svg {
                width: 18px;
                height: 18px;
            }
        }

        .select__indicator-separator {
            display: none;
        }
    }

    .select__menu {
        .select__option {
            display: flex;
            gap: 8px;
            cursor: pointer;

            &.select__option--is-selected {
                color: unset;
                background-color: unset;
            }

            &.select__option--is-disabled {
                cursor: default;

                &:hover {
                    background-color: $white;
                }
            }

            &:hover,
            &.select__option--is-focused {
                background-color: $background-light;
            }

            .option-checkbox {
                pointer-events: none;
            }
        }
    }
}
