@import "global/constants";

.note-tooltip {
    .arrow-box {
        box-shadow: 0px 8px 24px -8px rgba(3, 13, 29, 0.15);
        position: relative;
        background: $white;
        border: 1px solid $light-border;
        height: fit-content;
        width: fit-content;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px;

        .gathered-info {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $darkest-gray;
            margin-bottom: 5px;
            max-width: 180px;
            word-wrap: break-word;
        }

        .info-label-heading {
            display: inline;
            font-weight: bold;
            margin-bottom: 0px;
            line-height: 16px;
            color: $darkest-gray;
            font-size: 12px;
        }
    }

    .arrow-box:after, .arrow-box:before {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .arrow-box:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: $white;
        border-width: 10px;
        margin-top: -10px;
    }

    .arrow-box:before {
        border-color: rgba(226, 229, 233, 0);
        border-right-color: $light-border;
        border-width: 11px;
        margin-top: -11px;
    }
}
