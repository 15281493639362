@import "global/constants";

.dashboard {
    @include page;

    .page-title {        
        div, svg {
            display: inline;
        }
        
        .fleet-tooltip {
            margin-left: 10px;
            
            .info-icon {
                width: 24px;
                height: 24px;
            }

            .center {
                margin-left: -89px;
            }
        }
    }

    .issues {
        [class*="col-"] {
            display: inline-block;
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    .overview-box {
        height: 200px;
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
        cursor: pointer;

        &:hover {
            border-color: $blue;
        }

        .title {
            margin-left: 13px;
        }

        .status-data {
            padding: 0 10px;

            .current-active {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                font-style: normal;
                font-weight: 500;
                font-size: 45px;
                margin-bottom: 0;
            }

            .num-resolved, .num-snoozed {
                color: $darkest-gray;
                font-style: normal;
                font-size: 11px;
                line-height: 14px;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                margin-bottom: 0;
                margin-left: 3px;
            }

            .num-snoozed {
                font-size: 10px;
                margin-left: 5px;
            }
        }
    }
}

.modal-open .dashboard {
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
}
