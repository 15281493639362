@import "global/constants.scss";

.simple-tooltip {
    position: absolute;
    z-index: 102;
    display: flex;
    visibility: hidden;
    cursor: default;

    justify-content: center;
    align-items: center;
    text-align: center;

    &:hover {
        cursor: text;
    }

    &.left-align {
        text-align: left;
    }

    &.right-align {
        text-align: right;
    }

    &.center-align {
        text-align: center;
    }

    &.top {
        bottom: 100%;
        left: 50%;
        padding-bottom: 10px;
        transform: translate(-50%);

        .tooltip-arrow {
            top: 100%;
            margin-top: -10px;

            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top-width: 6px;
        }
    }

    &.right {
        left: 100%;
        top: 0;
        bottom: 0;
        padding-left: 10px;

        .tooltip-arrow {
            right: 100%;
            margin-right: -10px;

            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-right-width: 6px;
        }
    }

    &.bottom {
        top: 100%;
        left: 50%;
        padding-top: 10px;
        transform: translate(-50%);

        .tooltip-arrow {
            bottom: 100%;
            margin-bottom: -10px;

            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom-width: 6px;
        }
    }

    &.left {
        right: 100%;
        top: 0;
        bottom: 0;
        padding-right: 10px;

        .tooltip-arrow {
            left: 100%;
            margin-left: -10px;

            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left-width: 6px;
        }
    }

    .tooltip-arrow {
        border-width: 0;
        border-style: solid;
        border-color: $background-dark;
        position: absolute;
        width: 0;
        height: 0;
    }

    .tooltip-content {
        border-radius: 6px;
        font-size: 12px;
        padding: 4px 10px;
        white-space: pre;
        color: white;
        background-color: $background-dark;
    }
}
