@import "global/constants";

.email-recipient-groups {
    padding-top: 8px;
    color: $darkest-gray;
    padding-bottom: 20px;

    .header {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        background-color: transparent;
    }

    .btn-continue {
        background-color: $green;
        color: $white;
        font-style: normal;
        font-size: 11px;
        font-weight: 700;
        line-height: 14px;
        align-items: center;
        letter-spacing: 0.08em;
        border-radius: 2px;
        margin-right: 24px;
        padding: 10px 16px;

        .add-icon {
            margin-right: 5px;
        }
    }

    .expand {
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        
        .expand-arrow {
            margin-left: 5px;
        }
    }

    .division {
        color: $grey;
        font-weight: bold;
        margin-left: 5px;
    }
}
