@import "global/constants";

.modal {
    .close-modal {
        .modal-content {
            width: 298px;
            max-height: 428px;
            margin: auto;

            .modal-container { 
                margin-bottom: 0;
                max-height: 428px;
                border: none;
                padding-bottom: 80px;

                .warning-text {
                    font-style: italic;
                    font-size: 10px;
                    line-height: 13px;
                    color: $red;
                    margin: 12px 0;
                }

                .last-warning-header {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $red;
                    margin-top: 32px;
                    margin-bottom: 12px;
                }

                .last-warning-text {
                    font-size: 12px;
                    line-height: 16px;
                }

                .header {
                    width: 75%;
                    display: flex;
                    flex-direction: row;

                    .heading-label {
                        display: inline;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 16px;
                        max-width: 75px;
                        flex: 1;
                        margin-bottom: 8px;
                    }

                    .info {
                        display: inline;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        margin-bottom: 8px;
                    }
                }

                .action-label {
                    display: inline-block;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    margin-bottom: 4px;

                    .required-text {
                        color: $red;
                        font-style: italic;
                        font-size: 10px;
                        line-height: 13px;
                    }
                }

                .reason-box {
                    width: 100%;
                    height: 100px;
                    min-height: 30px;
                    max-height: 100px;
                    border: 1px solid $light-border;
                    box-sizing: border-box;
                    border-radius: 4px;
                    font-size: 12px;
                    line-height: 16px;
                    resize: none;
                }
            
                .basic-single-duration {
                    margin-left: 7px;
                    display: inline-block;
                    vertical-align: middle;
            
                    .select-duration__control {
                        width: 116px;
                        height: 32px;
                        min-height: 32px;
            
                        .select-duration__value-container {
                            height: 32px;

                            .select-duration__single-value, .select-duration__placeholder {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
            
                        .select-duration__indicators {
                            height: 32px;
                            .select-duration__indicator-separator {
                                display: none;
                            }
                        }
                    }
            
                    .select-duration__menu {
                        .select-duration__menu-list {
                            height: 128px;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }

                .basic-single-reason {
                    margin-bottom: 10px;
                    .select-reason__control {
                        height: 32px;
                        min-height: 32px;

                        .select-reason__value-container {
                            height: 32px;
                            .select-reason__single-value, .select-reason__placeholder {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
            
                        .select-reason__indicators {
                            height: 32px;
                            .select-reason__indicator-separator {
                                display: none;
                            }
                        }
                    }

                    .select-reason__menu {
                        .select-reason__menu-list {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }

                .character-count {
                    position: absolute;
                    right: 24px;
                    bottom: 60px;
                    font-weight: 500;
                    font-size: 8px;
                    line-height: 10px;
                    background: $white;
                }

                .buttons {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;

                    .btn-danger {
                        background-color: $red;
                        border-color: $red;
                        color: $white;
                        font-size: 11px;
                        font-weight: 700;
                        line-height: 14px;
                        letter-spacing: 0.08em;
                        border-radius: 2px;
                    }
                    
                    .btn-danger:disabled {
                        background-color: $header-border-color;
                        border-color: $header-border-color;
                    }

                    .cancel-button, .close-button {
                        display: inline;
                        height: 36px;
                        width: 116px;
                        text-transform: uppercase;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 14px;
                        padding: 0px;
                    }

                    .cancel-button {
                        margin-right: 10px;
                    }
                }    
            }
        }
    }
}
