@import "global/constants";

.chip {
    cursor: pointer;
    padding: 2px 4px;
    background-color: $table-header-color;
    border: 1px solid $grey;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    .category-icon {
        color: $darkGrey;
        margin-right: 3px;
        font-weight: 900;
        font-size: 12px;
        line-height: 12px;
    }
}
