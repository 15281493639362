@import "global/constants";

.report-history {
    @include page;

    .breadcrumb {
        padding: 0.75rem 0;
        background-color: transparent;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        padding-left: 0;
        margin-top: 11px;
        margin-bottom: 8px;

        .breadcrumb-item a {
            color: $darkGrey;
        }

        .active a {
            color: $black;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: ">";
        }
    }

    .heading-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 16px;

        .heading {
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 47px;
            color: $darkest-gray;
            padding-left: 0;
            margin-bottom: 0;
            margin-right: 8px;
        }

        .filter-container {
            display: flex;

            .filter {
                width: 156px;
                margin-left: 12px;
                margin-top: -12px;
            }

            .not-selected {
                color: $darkGrey;
            }

            .picker {
                width: 156px;
                height: 32px;
                font-size: 12px;
                line-height: 16px;
                border: 1px solid $light-border;
                border-radius: 4px;
            }

            label {
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 0;
            }
        }
    }

    .report-history-div {
        width: 100%;

        .dt-align-right {
            text-align: right;
        }

        .dt-align-left {
            text-align: left;
        }

        .table {
            th {
                background-color: $table-header-color;
                border-top: 1px solid $grey;
                border-right: 1px solid $grey;
            }

            th:first-child {
                border-left: 1px solid $grey;
            }

            tbody {
                tr {
                    height: 40px;
                    border: 1px solid $light-border;
                    border-bottom: none;
                    box-sizing: border-box;
                    cursor: pointer;

                    .count-cell {
                        padding-right: 18px;
                    }

                    .light-bold {
                        font-weight: 600;
                    }

                    td:last-child {
                        padding-right: 10px;
                    }

                    &:nth-of-type(odd) {
                        background: $white;
                    }

                    &:nth-of-type(even) {
                        background: $background-light;
                    }
                }

                tr:hover {
                    background-color: $table-header-color;
                    box-shadow: 0 4px 8px $light-border;

                    td {
                        border-top-color: $header-border-color;
                        border-bottom-color: $header-border-color;

                        &:first-child {
                            border-left-color: $header-border-color;
                        }

                        &:last-child {
                            border-right-color: $header-border-color;
                        }
                    }
                }
            }
        }
    }
}
