@import "global/constants";

.trailer-audit {
    @include page;

    .message-card {
        padding: 18px 55px;
        margin: 0 -55px;

        .message {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .breadcrumb {
        padding: 0.75rem 0;
        background-color: transparent;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        padding-left: 0;
        margin-top: 11px;
        margin-bottom: 8px;

        .breadcrumb-item a {
            color: $darkGrey;
        }

        .active a {
            color: $black;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: ">";
        }
    }

    .heading-div {
        display: flex;
        align-items: center;
        padding-top: 24px;
    }

    .content-below-title {
        margin-bottom: 30px;
    }

    .heading-error-message-div {
        background: rgba(211, 66, 78, 0.08);
        border: 1px solid rgba(211, 66, 78, 0.4);
        margin: 10px 0 0 15px;
        display: flex;
        align-items: center;
        max-width: max-content;
        height: 26px;
        padding: 0 8px;
        font-size: 15px;

        span {
            margin-left: 4px;
        }
    }

    .row-items {
        margin-left: -10px;
        margin-right: -10px;
    }

    .export-csv-div {
        display: flex;
        align-items: center;
    }

    .page-title {
        margin-right: 10px;

        .tooltip-info-icon {
            font-size: 24px;
            color: $grey;
            margin-bottom: 3px;
        }

        .tooltip-container {
            position: relative;
            margin-left: 10px;
            display: inline-block;
            cursor: pointer;

            .simple-tooltip {
                line-height: 18px;
            }
        }

        .tooltip-container:hover {
            .tooltip-info-icon {
                color: $darkest-gray;
            }

            .simple-tooltip {
                visibility: visible;
            }
        }
    }

    .page-updated {
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: $darkGrey;
        margin-left: 8px;
    }

    .btn-continue {
        background-color: $green;
        color: $white;
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        border-radius: 2px;
        padding: 10px 16px;
        display: flex;

        .add-icon {
            font-weight: 900;
            font-size: 12px;
            line-height: 12px;
            margin-right: 5px;
        }
    }

    .csv-loader {
        margin-left: 5px;
    }

    .button {
        display: flex;
        align-items: center;
        padding: 9px 16px;
        width: max-content;
        background: $white;
        border: 1px solid $light-border;
        border-radius: 2px;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        color: $darkest-gray;

        &:hover {
            text-decoration: none;
        }

        .icon {
            color: $darkest-gray;
            font-size: 14px;
            margin-right: 5px;
        }
    }

    .disabled {
        opacity: 0.3;
    }

    .box-col {
        padding: 0 10px;

        .box {
            border-color: $white;
            height: 154px;
            font-size: 11px;
            line-height: 14px;
            padding: 16px;
            margin-bottom: 20px;
            cursor: pointer;

            .title {
                line-height: 16px;
                margin-left: 0;
                margin-right: 8px;
                margin-bottom: 4px;

                .tooltipText {
                    top: -2px;
                    right: -8px;
                }
            }

            .total-count-container {
                color: $blue;
                display: flex;
                align-items: center;

                .yard-icon-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    width: 38px;
                    height: 38px;
                    margin-right: 8px;
                    border-radius: 8px;
                    background-color: $light-blue;
                }

                .total-count {
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 48px;
                    margin-bottom: 4px;
                }
            }

            .yard-info {
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
                color: $darkest-gray;
                line-height: 16px;
            }

            &:hover {
                border-color: $blue;
                box-shadow: 0 4px 8px $light-border;
            }
        }

        .fleet-box {
            height: 135px;
            padding: 20px;

            .total-count {
                font-size: 48px;
                font-weight: normal;
                line-height: 63px;
            }
        }

        .disabled-box {
            cursor: default;
            color: $darkGrey;

            .total-count-container {
                color: $darkGrey;

                .yard-icon-box {
                    background-color: $icon-background;
                }
            }

            &:hover {
                border-color: $white;
                box-shadow: none;
            }
        }
    }

    .issues-container {
        padding-bottom: 24px;
        margin-top: 16px;
        margin-bottom: 20px;

        .detached-issue-warning, .missing-issue-warning, .no-issues-info {
            max-width: max-content;
            height: 26px;
            padding: 0 8px;
            font-size: 15px;
            margin-top: 20px;

            span {
                margin-left: 4px;
            }
        }

        .no-issues-info {
            background: rgba(40, 154, 194, 0.08);
            border: 1px solid rgba(40, 154, 194, 0.4);
        }

        .detached-issue-warning {
            background: rgba(242, 124, 70, 0.08);
            border: 1px solid rgba(242, 124, 70, 0.4);
            margin-bottom: 20px;
        }

        .missing-issue-warning {
            background: rgba(211, 66, 78, 0.08);
            border: 1px solid rgba(211, 66, 78, 0.4);
            margin-bottom: 20px;
        }

        .detached-missing-table-container {
            width: 100%;

            .dt-align-right {
                text-align: right;
            }

            .dt-align-left {
                text-align: left;
            }

            .dt-align-center {
                text-align: center;
            }

            .table {
                tbody {
                    .action-items {
                        display: flex;
                        justify-content: space-between;
                    }

                    .light-bold {
                        font-weight: 600;
                    }

                    .action-items > div {
                        padding: 0 4px;
                    }

                    .seen-icon, .comment-icon {
                        cursor: pointer;
                    }

                    .comment-icon.medium {
                        color: $darkGrey;
                    }

                    .comment-icon.light:hover, .comment-icon.medium:hover {
                        color: $background-dark;
                        fill: $background-dark;
                    }

                    .comment-icon.light {
                        color: $grey;
                    }
                }
            }
        }
    }

    .title-small {
        font-size: 20px;
        font-weight: bold;
        line-height: 26px;
        margin-bottom: 16px;
        margin-top: 12px;
    }

    .last-row {
        padding-bottom: 33px;
    }
}
