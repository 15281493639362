@import "global/constants";

.seen-by-tooltip {
    .right-arrow-box {
        box-shadow: 0px 8px 24px -8px rgba(3, 13, 29, 0.15);
        position: relative;
        background: $white;
        border: 1px solid $light-border;
        height: fit-content;
        width: fit-content;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px; 

        .info-label {
            font-weight: 500;
            line-height: 16px;
            color: $darkest-gray;
            font-size: 12px;
            font-style: italic;
        }

        .content {
            width: 100%;
            display: flex;
            flex-direction: row;

            .circle {
                height: 16px;
                width: 16px;
                margin-right: 7px;
                margin-top: 1px;
                background-color: $circle-background;
                border-radius: 50%;
                display: inline-block;
            
                .initials {
                    font-size: 6px;
                    line-height: 7px;
                    text-align: center;
                    margin-top: 25%;
                    color: $darkest-gray;
                }
            
            }

            .name, .time {
                font-weight: 500;
                line-height: 16px;
                color: $darkest-gray;
                margin-bottom: 3px;
            }
    
            .name {
                font-size: 12px;
                text-align: left;
                flex: 1;
            }
    
            .time {
                margin-left: 15px;
                font-style: italic;
                font-size: 10px;
                text-align: right;

            }
        }
    }

    .right-arrow-box:after, .right-arrow-box:before {
        left: 100%;
        top: 80%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;    
    }

    .right-arrow-box:after {
        border-color: rgba(136, 183, 213, 0);
        border-left-color: $white;
        border-width: 10px;
        margin-top: -10px;    
    }

    .right-arrow-box:before {
        border-color: rgba(194, 225, 245, 0);
        border-left-color: $light-border;
        border-width: 11px;
        margin-top: -11px;    
    }
}