@import "global/constants";

.report-issue-trailers {

    @media only screen and (max-width: 992px) {
        margin: 2%;
    }

    .message-card {
        padding: 18px 55px;
        margin: 0 -55px;

        .message {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .breadcrumb {
        padding: 0.75rem 0;
        background-color: transparent;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        padding-left: 0;
        margin-top: 11px;
        margin-bottom: 8px;
        padding-bottom: 0;

        .breadcrumb-item a {
            color: $darkGrey;
        }

        .active a {
            color: $black;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: ">";
        }
    }

    .refresh-button {
        display: flex;
        align-items: center;
        padding: 9px 16px;
        width: max-content;
        background: $white;
        border: 1px solid $light-border;
        border-radius: 2px;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        color: $darkest-gray;

        &:hover {
            text-decoration: none;
        }

        .icon {
            color: $darkest-gray;
            font-size: 14px;
            margin-right: 5px;
        }
    }

    .disabled {
        opacity: 0.3;
    }

    .heading-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .heading {
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 47px;
            color: $darkest-gray;
            padding-left: 0;
            margin-bottom: 0;
            margin-right: 8px;

            .trailer-count {
                font-size: 20px;
                line-height: 26px;
                color: $darkGrey;
            }
        }

        .refresh-button {
            margin-right: 16px;
        }

        div, svg {
            display: inline;
        }

        .btn-continue {
            background-color: $green;
            color: $white;
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            border-radius: 2px;
            padding: 10px 16px;

            .add-icon {
                font-weight: 900;
                font-size: 12px;
                line-height: 12px;
                margin-right: 5px;
            }
        }

        .csv-loader {
            margin-left: 5px;
        }
    }

    .report-trailers-div {
        width: 100%;
        margin-top: 30px;
        padding-bottom: 20px;

        .dt-align-right {
            text-align: right;
        }

        .dt-align-center {
            text-align: center;
        }

        .long-column {
            white-space: break-spaces;
            word-wrap: break-word;
        }

        .comment-icon:hover,
        .seen-icon:hover {
            cursor: pointer;
        }

        .disabled-seen-icon,
        .disabled-comment-icon {
            color: $disabled-snooze-color;

            &:hover {
                color: $background-dark;
                cursor: pointer;
            }
        }

        .table {
            th {
                background-color: $table-header-color;
                border-top: 1px solid $grey;
                border-right: 1px solid $grey;
            }

            th:first-child {
                border-left: 1px solid $grey;
            }

            tbody {
                .action-items {
                    display: flex;
                    justify-content: space-evenly;
                }

                .action-items > div {
                    padding: 0 4px;
                }

                tr {
                    height: 40px;
                    border: 1px solid $light-border;
                    border-bottom: none;
                    box-sizing: border-box;

                    td:last-child {
                        padding-right: 10px;
                    }

                    &:nth-of-type(odd) {
                        background: $white;
                    }

                    &:nth-of-type(even) {
                        background: $background-light;
                    }
                }

                tr:hover {
                    background-color: $table-header-color;
                    box-shadow: 0 4px 12px -4px $light-border;

                    td {
                        border-top-color: $header-border-color;
                        border-bottom-color: $header-border-color;

                        &:first-child {
                            border-left-color: $header-border-color;
                        }

                        &:last-child {
                            border-right-color: $header-border-color;
                        }
                    }
                }
            }
        }
    }
}
