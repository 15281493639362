@import "global/constants";

.not-authorized {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);

    .image-container {
        width: 451px;
        height: 540px;
        background: url("../../files/not-authorized/not_authorized.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-origin: content-box;
        margin-right: 11px;
        margin-left: 11px;
        padding: 50px;
    }

    .text-container {
        width: 390px;
        color: $background-dark;
        margin: 11px;

        .logo-container {
          width: 118px;
          height: 40px;
          background: url("../../files/logo/logo.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }

        .title {
          font-weight: 900;
          font-size: 36px;
          line-height: 47px;
          letter-spacing: 0.02em;
        }

        .description {
          font-size: 20px;
          line-height: 26px;
        }
    }
}
