@import "global/constants.scss";

.table-container {
    .table {
        border-top: 1px solid $grey;

        .dt-align-right {
            text-align: right;
        }

        th {
            background-color: $table-header-color;
            border-top: 1px solid $grey;
            border-right: 1px solid $grey;

            &:first-child {
                border-left: 1px solid $header-border-color;
            }
        }

        tbody {
            tr {
                border: 1px solid $light-border;
                border-bottom: none;
                box-sizing: border-box;

                td {
                    padding: 12px 10px;
                }

                &:nth-of-type(odd) {
                    background: $white;
                }

                &:nth-of-type(even) {
                    background: $background-light;
                }

                &:hover {
                    background-color: $table-header-color;
                    box-shadow: 0px 4px 12px -4px $light-border;

                    td {
                        border-top-color: $header-border-color;
                        border-bottom-color: $header-border-color;

                        &:first-child {
                            border-left-color: $header-border-color;
                        }

                        &:last-child {
                            border-right-color: $header-border-color;
                        }
                    }
                }
            }
        }
    }
}
