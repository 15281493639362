@import "global/constants";

.modal {
    .delete-reason-modal {
        .modal-content {
            width: 300px;
            margin: auto;

            .modal-container {
                margin-bottom: 0;
                border: none;
                padding-bottom: 80px;

                .warning-header {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $red;
                    margin-top: 32px;
                    margin-bottom: 12px;
                }

                .warning-text {
                    font-size: 12px;
                    line-height: 16px;
                }

                .buttons {
                    position: absolute;
                    bottom: 20px;
                    right: 20px;

                    .btn-danger {
                        background-color: $red;
                        border-color: $red;
                        color: $white;
                        font-size: 11px;
                        font-weight: 700;
                        line-height: 14px;
                        letter-spacing: 0.08em;
                        border-radius: 2px;
                    }
                    
                    .btn-danger:disabled {
                        background-color: $header-border-color;
                        border-color: $header-border-color;
                    }

                    .cancel-button, .delete-button {
                        display: inline;
                        height: 36px;
                        width: 106px;
                        text-transform: uppercase;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 14px;
                        padding: 0px;

                        &:disabled {
                            color: $white;
                        }
                    }

                    .cancel-button {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
