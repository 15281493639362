@import "global/constants";

.pill-container {
    position: relative;
    width: 32px;
    height: 16px;
    background-color: #56CCF2;
    box-shadow: 0px 0px 12px rgba(23, 36, 55, 0.1), inset 0px 4px 4px rgba(244, 245, 250, 0.1);
    border-radius: 8px;
    cursor: pointer;

    .pill {
        position: absolute;
        top: 2px;
        left: 3px;
        width: 12px;
        height: 12px;
        background-color: white;
        box-shadow: 0px 2px 2px rgba(3, 13, 29, 0.1);
        border-radius: 6px;

        &.checked {
            left: 17px;
        }
    }
}

.grey-pill-container {
    background-color: $table-header-color;
    border: 1px solid $light-border;

    .pill {
        top: 1px;
        left: 2px;
    }
}

.disabled {
    opacity: 0.3;
    cursor: default;
}
