@import "global/constants";

.modal {
    .snooze-reject-modal{
        .modal-content {
            width: 298px;
            height: 172px;
            margin: auto;

            .modal-container {
                margin-bottom: 0;
                height: 172px;
                border: none;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .modal-text {
                    font-size: 12px;
                    line-height: 16px;
                    margin-top: 30px;
                }
            }
        }
    }
}
