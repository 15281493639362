@import "global/constants";

.active-selection {
  display: inline-block;
  .info-label {
    margin-right: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 32px;
    float: left;
  }

  .disabled {
    color: $header-border-color;
  }

  .buttons {
    border: 1px solid $light-border;
    box-sizing: border-box;
    border-radius: 4px;
    float: left;

    .left {
      border-radius: 3px 0 0 3px;
    }

    .right {
      border-radius: 0 3px 3px 0;
    }
    
    .active {
      background-color: white;
      color: $darkest-gray;
      float: left;
      width: 109px;
      height: 32px;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin: 0 auto;
    }
    .inactive {
      background-color: $table-header-color;
      color: $grey;
      float: left;
      width: 109px;
      height: 32px;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin: 0 auto;
    }

    .longer-text {
      padding: 8px;
    }

    .btn:focus {
      outline: none;
      box-shadow: none;
    }

  }

}
