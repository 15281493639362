@import "global/constants";

.mismatch-issues {
    @media only screen and (max-width: 992px) {
        margin: 2%;
    }

    .breadcrumb {
        padding: 0.75rem 0;
        background-color: transparent;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-top: 11px;

        .breadcrumb-item a {
            color: $darkGrey;
        }

        .active a {
            color: $black;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: ">";
        }
    }

    .heading-div {
        display: inline-block;
        width: 100%;

        .heading {
            float: left;
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 47px;
            color: $darkest-gray;
        }

        div, svg {
            display: inline;
        }

        .tutorial-icon {
            font-size: 24px;
            font-weight: 900;
            line-height: 24px;
            color: $header-border-color;
            cursor: pointer;
            margin: 16px 8px 0 8px;
        }

        .tutorial-icon:hover {
            color: $darkest-gray;
        }
    }

    .active-toggle {
        float: right;
        margin-bottom: 5px;
    }

    .mismatch-issues-div {
        width: 100%;

        .dt-align-right {
            text-align: right;
        }

        .dt-align-left {
            text-align: left;
        }

        .dt-align-center {
            text-align: center;
        }

        .table {
            .long-column {
                white-space: break-spaces;
                word-wrap: break-word;
            }

            th {
                background-color: $table-header-color;
                border-top: 1px solid $grey;
                border-right: 1px solid $grey;
            }

            th:first-child {
                border-left: 1px solid $grey;
            }

            tbody {
                .action-items {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                }

                .action-items > div {
                    padding: 0 4px;
                }

                .notifications {
                    .notification-level, .notification-info, .snoozed-info {
                        display: inline;
                    }
                }

                .snooze-icon, .seen-icon, .info-icon, .comment-icon, .resolve-icon {
                    cursor: pointer;
                }

                .snooze-icon.medium, .comment-icon.medium {
                    color: $darkGrey;
                }

                .snooze-icon.medium:hover, .disabled-icon-snooze.light:hover, .disabled-icon:hover, .comment-icon.light:hover, .comment-icon.medium:hover, .resolve-icon:hover {
                    color: $background-dark;
                    fill: $background-dark;
                }

                .disabled-icon-snooze.light, .comment-icon.light, .resolve-icon {
                    color: $grey;
                }

                .disabled-icon > path {
                    color: $disabled-snooze-color;
                    fill: $disabled-snooze-color;
                }

                .disabled-icon:hover > path {
                    fill: $background-dark;
                }

                tr {
                    height: 40px;
                    border: 1px solid $light-border;
                    border-bottom: none;
                    box-sizing: border-box;

                    &:nth-of-type(odd) {
                        background: $white;
                    }

                    &:nth-of-type(even) {
                        background: $background-light;
                    }

                    td:last-child {
                        padding-right: 10px;
                    }
                }

                tr:hover {
                    background-color: $table-header-color;
                    box-shadow: 0 4px 12px -4px $light-border;

                    td {
                        border-top-color: $header-border-color;
                        border-bottom-color: $header-border-color;

                        &:first-child {
                            border-left-color: $header-border-color;
                        }

                        &:last-child {
                            border-right-color: $header-border-color;
                        }
                    }
                }
            }

            .resolvedContainer {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
        }
    }
}
