@import "global/constants";

.detached-missing-trailer-issues {

  @media only screen and (max-width: 992px) {
    margin: 2%;
  }

  .breadcrumb {
    padding: 0.75rem 0;
    background-color: transparent;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin-top: 11px;

    .breadcrumb-item a {
      color: $darkGrey;
    }

    .active a {
      color: $black;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      content: ">";
    }
  }

  .heading-div {
    display: inline-block;
    width: 100%;

    .heading {
      float: left;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 47px;
      color: $darkest-gray;
    }

    div, svg {
      display: inline;
    }

    .tutorial-icon {
      font-size: 24px;
      font-weight: 900;
      line-height: 24px;
      color: $header-border-color;
      cursor: pointer;
      margin: 14px 8px 0 10px;
    }

    .tutorial-icon:hover {
      color: $darkest-gray;
    }
  }

  .active-toggle {
    float: right;
    margin-bottom: 5px;
  }

  .detached-missing-trailer-issues-div {
    width: 100%;

    .dt-align-right {
      text-align: right;
    }

    .dt-align-left {
      text-align: left;
    }

    .dt-align-center {
      text-align: center;
    }

    .table {
      th {
        background-color: $table-header-color;
        border-top: 1px solid $grey;
        border-right: 1px solid $grey;
      }

      th:first-child {
        border-left: 1px solid $grey;
      }

      tbody {
        .action-items {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
        }

        .action-items > div {
          padding: 0 4px;
        }

        .notifications {
          .notification-level, .notification-info, .snoozed-info {
            display: inline;
          }
        }

        .snooze-icon, .seen-icon, .info-icon, .comment-icon, .resolve-icon {
          cursor: pointer;
        }

        .snooze-icon.medium, .comment-icon.medium {
          color: $darkGrey;
        }

        .snooze-icon.medium:hover, .disabled-icon-snooze.light:hover, .disabled-icon:hover, .comment-icon.light:hover, .comment-icon.medium:hover, .resolve-icon:hover {
          color: $background-dark;
          fill: $background-dark;
        }

        .disabled-icon-snooze.light, .comment-icon.light, .resolve-icon {
          color: $grey;
        }

        .disabled-icon > path {
          color: $disabled-snooze-color;
          fill: $disabled-snooze-color;
        }

        .disabled-icon:hover > path {
          fill: $background-dark;
        }

        tr {
          height: 40px;
          border: 1px solid $light-border;
          border-bottom: none;
          box-sizing: border-box;

          &:nth-of-type(odd) {
            background: $white;
          }

          &:nth-of-type(even) {
            background: $background-light;
          }

          td:last-child {
            padding-right: 10px;
          }
        }

        tr:hover {
          background-color: $table-header-color;
          box-shadow: 0 4px 12px -4px $light-border;

          td {
            border-top-color: $header-border-color;
            border-bottom-color: $header-border-color;

            &:first-child {
              border-left-color: $header-border-color;
            }

            &:last-child {
              border-right-color: $header-border-color;
            }
          }
        }
      }

      .resolvedContainer {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
    }
  }
}

.tutorial-modal-location {
  margin-bottom: 0;

  .tutorial-modal-subtitle {
    font-size: 12px;
    text-align: center;
    margin-bottom: 15px;
    max-width: 640px;
  }

  .modal-dialog {
    max-width: 1020px;

    .modal-content {
      width: 1020px;

      .vll {
        border-left: 1px solid $darkGrey;
        border-bottom: 1px solid $darkGrey;
        height: 12px;
        width: 12px;
        margin-left: 20%;
      }

      svg {
        margin: auto;
      }

      .step-text, .modal-text {
        margin-bottom: 0;
        color: $background-dark;
      }

      .step-text {
        font-size: 11px;
        font-weight: bold;
        line-height: 14px;
      }

      .modal-text {
        font-size: 12px;
        line-height: 16px;
      }

      .vll-container {
        vertical-align: top;
        padding-top: 12px;
      }

      .action-text {
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: $blue;
        font-weight: 600;
        text-decoration: underline;
        margin-top: 10px;
        margin-bottom: 24px;
        width: 100%;
      }

      .form-check {
        margin-top: 5px;
        text-align: center;
        margin-right: 80px;

        .form-check-input {
          margin-top: .1rem;
        }

        .checkbox-text {
          font-size: 11px;
          font-weight: 500;
        }
      }
    }
  }
}

.modal-open .detached-missing-trailer-issues {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}
