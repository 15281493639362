@import "global/constants";

.help-center {
    @include page;

    font-size: 12px;
    line-height: 16px;
    color: $darkest-gray;
    padding-bottom: 24px;

    .nav-tabs {
        margin-bottom: 24px;

        .tab {
            color: $darkGrey;

            .tab-icon {
                color: $grey;
                margin-right: 4px;
            }
        }

        .tab.active {
            color: $darkest-gray;
            border-bottom-color: $background-light;
            background: transparent;

            .tab-icon {
                color: $darkest-gray;
            }
        }
    }

    ol:first-child,
    ul:first-child {
        padding-top: 16px;
    }

    .tab-description {
        padding-bottom: 12px;
        max-width: 743px;

        ol {
            margin-bottom: 0;
        }
    }

    .expandable-card {
        .content {
            display: block;
            max-width: 743px;

            h6:not(:first-child) {
                margin-top: 32px;
            }

            h6 {
                font-weight: bold;
            }

            img {
                max-width: 100%;
            }

            .section {
                padding-left: 26px;
            }

            .title-icon {
                color: $green;
                margin-right: 12px;
            }
        }
    }
}
