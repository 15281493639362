.commands-table {

    .command-label {
        font-weight: 600;
        margin-right: 4px;
    }

    .command-status-label {

        svg {
            color: #030D1D;
            margin-left: 4px;
        }

        &:hover {
            .simple-tooltip {
                z-index: 999;
                visibility: visible;
            }
        }
    }
}
