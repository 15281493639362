@import "global/constants";

.drop-yards {
    padding-bottom: 5px;

    .row {
        margin-bottom: 12px;

        .col {
            text-align: right;

            .btn-continue {
                background-color: $green;
                color: $white;
                font-style: normal;
                font-size: 11px;
                font-weight: 700;
                line-height: 14px;
                align-items: center;
                letter-spacing: 0.08em;
                width: 150px;
                height: 36px;
                border-radius: 2px;

                .add-icon {
                    margin-right: 5px;
                }
            }
        }
    }

    .simple-container {
        padding-bottom: 0;

        .dt-align-center {
            text-align: center;
        }

        .actions {
            color: $grey;

            svg {
                cursor: pointer;
                margin: 0 6px;

                &:hover {
                    color: $background-dark;
                }
            }
        }
    }
}
