@import "global/constants";

.trailer-map {
    .trailer-popup {
        .trailer-popup-content {
            font-size: 12px;
            line-height: 16px;

            &.content-not-loaded {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 301px;
                height: 261px;
            }

            .trailer-popup-icon {
                font-size: 20px;
                margin-right: 4px;
                padding: 5px 2px;

                &.green {
                    color: $reefer-success-400;
                }

                &.red {
                    color: $red;
                }

                &.yellow {
                    color: $reefer-warning-400;
                }

                &.orange {
                    color: $orange;
                }

                &.blue {
                    color: $blue;
                }
            }

            .trailer-popup-header {
                font-size: 15px;
                font-weight: 700;
            }

            .trailer-popup-subheader {
                font-size: 11px;
                line-height: 16px;
                font-weight: 500;
                color: $grey;
            }

            .fuel-level-icon {
                color: $grey;
                margin-right: 8px;

                &.red {
                    color: $red;
                }
            }

            .popup-external-link {
                color: $grey;
                margin-left: 4px;
            }

            .line {
                width: 100%;
                height: 1px;
                background-color: $grey;
                margin-top: 12px;
            }

            .info-label {
                font-size: 11px;
                font-weight: 700;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: $darkGrey;
                margin-top: 12px;
            }
        }
    }

    .selected-trailer-marker {
        z-index: 999 !important;
    }

    .custom-trailer-map-legend-container {
        background-color: rgba($white, 0.9);
        padding: 4px 12px;
    }
}

.trailer-map-legend-container {
    display: flex;
    column-gap: 24px;
    margin-left: auto;
    padding-top: 4px;
    padding-bottom: 4px;
    white-space: nowrap;

    .legend-icon {
        margin-right: 4px;

        &.green {
            color: $reefer-success-400;
        }

        &.red {
            color: $red;
        }

        &.yellow {
            color: $reefer-warning-400;
        }

        &.orange {
            color: $orange;
        }

        &.blue {
            color: $blue;
        }
    }

    @media (max-width: 768px) {
        flex-wrap: wrap;
        row-gap: 8px;
    }
}
