@import "global/constants";

.message-card {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid transparent;
    padding: 3px 8px;
    font-size: 14px;

    .icon {
        margin-right: 4px;
    }

    &.info {
        background: rgba(40, 154, 194, 0.12);
        border-color: $blue;

        .icon {
            color: $blue;
        }
    }

    &.error {
        background: rgba(211, 66, 78, 0.08);
        border-color: rgba(211, 66, 78, 0.4);

        .icon {
            color: $red;
        }
    }
}
