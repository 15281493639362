@import "global/constants";


.filter-form {
  display: inline-flex;
  margin-left: auto; 
  margin-right: 0;
  margin-top: 8px;

  @media (max-width: 735px) {
    padding-left: 15px;
  }

  div {
    margin-right: 8.5px;
  }

  .not-selected {
    color: $darkGrey;
  }

  .picker {
    height: 32px;
    background: #FFFFFF;
    border: 1px solid $light-border;
    box-sizing: border-box;
    border-radius: 4px;
    width: inherit;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */
    display: flex;
    align-items: center;


  }

  label {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */
    display: flex;
    align-items: flex-end;
    margin-bottom: 0;
    padding-left: 2px;
    color: #172437;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: 0px;
    border-radius: 2px;
  }

  .ant-select-selection-placeholder {
    color: $darkGrey;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }

  .ant-select-arrow {
    color: black;
    font-weight: 600;
  }

  input, select {
    height: 32px;
    background: #FFFFFF;
    border-top: 1px solid $light-border;
    border-bottom: 1px solid $light-border;
    box-sizing: border-box;
    border-radius: 0px;
    width: inherit;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */
    display: flex;
    align-items: center;

    color: $darkGrey;

    &:focus {
      border-top: 1px solid $light-border;
      border-bottom: 1px solid $light-border;
      box-sizing: border-box;
      border-radius: 0px;
      color: black;
    }
    
    &:hover {
      border-top: 1px solid $light-border;
      border-bottom: 1px solid $light-border;
      box-sizing: border-box;
      border-radius: 0px;
    }

    .chevron-icon {
      position: absolute;
      right: 5px;
      top: 10px;
    }
  
  }

  .team-select-div {
    width: 106px;

  .team-select-picker {
    position: relative;
    display: block;

    .picker-label {
        display: block;
        font-weight: 600;
        margin-left: 10px;
        margin-top: 7px;
        
        cursor: pointer;
        &.checked {
            color: black;
        }

        .multiple-teams {
            display: flex;
            vertical-align: middle;
            align-items: center;
        }

        svg {
            margin-right: 5px;
            margin-left: 5px;
        }
        
        .chevron-icon {
          position: absolute;
          right: 5px;
          top: 10px;
        }
    }

    .team-drop-menu {
        position: absolute;
        min-width: 160px;
        z-index: 100;
        top: 41px;
        right: 0;
        padding: 4px;
        margin: 0;

        background: white;
        border: 1px solid $light-border;
        box-sizing: border-box;
        border-radius: 4px;

        &.shown {
            display: block;
        }

        &.hidden {
            display: none;
        }

        .team-picker-item {
            display: flex;
            vertical-align: middle;
            align-items: center;
            position: relative;
            line-height: 0;
            padding: 8px;
            margin: 0;
            height: 32px;
            box-sizing: border-box;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;

            input[type=checkbox] { 
                display:none; 
                cursor: pointer;

                + label:before {
                    font-family: FontAwesome;
                    display: inline-block;
                    content: "\f0c8";
                    color: $grey;
                    letter-spacing: 10px;
                }

                &:checked + label:before {
                    content: "\f14a"; 
                    color: $darkGrey;
                    letter-spacing: 10px;
                }
            } 
             
            label {
                cursor: pointer;
                margin-left: 8px;
                margin-bottom: 0px;
            }

            &:hover {
                background-color: $background-light;
            }
        }
      }
    }
  }


  .issue-type-select-div {
    width: 146px;

  .issue-type-select-picker {
    position: relative;
    display: block;

    .picker-label {
        display: block;
        font-weight: 600;
        margin-left: 10px;
        margin-top: 7px;
        
        cursor: pointer;
        &.checked {
            color: black;
        }

        .multiple-types {
            display: flex;
            vertical-align: middle;
            align-items: center;
        }

        svg {
            margin-right: 5px;
            margin-left: 5px;
        }
        .chevron-icon {
          position: absolute;
          right: 5px;
          top: 10px;
        }
    }

    .issue-type-drop-menu {
        position: absolute;
        min-width: 160px;
        z-index: 100;
        top: 41px;
        right: 0;
        padding: 4px;
        margin: 0;

        background: white;
        border: 1px solid $light-border;
        box-sizing: border-box;
        border-radius: 4px;

        &.shown {
            display: block;
        }

        &.hidden {
            display: none;
        }

        .issue-type-picker-item {
            display: flex;
            vertical-align: middle;
            align-items: center;
            position: relative;
            line-height: 0;
            padding: 8px;
            margin: 0;
            height: 32px;
            box-sizing: border-box;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;

            input[type=checkbox] { 
                display:none; 
                cursor: pointer;

                + label:before {
                    font-family: FontAwesome;
                    display: inline-block;
                    content: "\f0c8";
                    color: $grey;
                    letter-spacing: 10px;
                }

                &:checked + label:before {
                    content: "\f14a"; 
                    color: $darkGrey;
                    letter-spacing: 10px;
                }
            } 
             
            label {
                cursor: pointer;
                margin-left: 8px;
                margin-bottom: 0px;
            }

            &:hover {
                background-color: $background-light;
            }
        }
      }
    }
  }
}


    .issue-type-drop-menu {
        min-width: 160px;
        font-weight: 600;

        background: white;
        border: 1px solid $light-border;
        box-sizing: border-box;
        border-radius: 4px;

        &.shown {
            display: block;
        }

        &.hidden {
            display: none;
        }
    }