@import "global/constants";

.trailer-map-page {
    @include page;

    .heading-container {
        display: flex;
        align-items: center;

        .page-title {
            margin-right: 10px;
            margin-bottom: 16px;
        }
    }

    .label-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        margin-bottom: 16px;
    }

    .trailer-map-container {
        background-color: $white;
        padding: 16px;
        border-radius: 4px;
    }
}
