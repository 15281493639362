@import "global/constants";

.statistics {
  .breadcrumb {
    padding: 0.75rem 0;
    background-color: transparent;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin-top: 11px;
    height: fit-content;

    .breadcrumb-item a {
      color: $darkGrey;
    }
    .active a{
      color: $black;
    }
    .breadcrumb-item + .breadcrumb-item::before {
      content: ">";
    }
  }

  .page-heading-row {
    margin-bottom: 24px;
  }
  
  .pageHeading {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 47px;
    margin-top: 8px;

    .with-margin {
      margin-left: 10px;
      margin-right: 5px;
    }
    .middle-align {
      vertical-align: middle;
    }

    .margin-right {
      margin-right: 5px;
    }
  }


}

.issues-orders-stats {
  background: white;
  box-sizing: border-box;
  border: 1px solid $light-border;
  border-radius: 4px;
  height: 582px;
  margin-top: 2%;
  padding-right: 0;
  padding-left: 0;

  .order-count {
    position: absolute;
    top: 30px;
    left: 36px;
    z-index: 1;
    text-align: right;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.08em;
    line-height: 26px;

    .orders {
      color: $darkGrey;
      font-size: 11px;
      font-weight: 700;
      line-height: 14px;
    }
  }
}


.legend-wrapper {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: 30px;
  margin-left: 50px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;

  .legend {
    display: flex;
    margin-right: 24px;
    margin-bottom: 2px;

    .inline {
      white-space: nowrap;
    }
  }

  .right {
    position: absolute;
    right: 10px;
    top: 5px;
    @media screen and (max-width: 1110px) {
      top: 10px;
    }
  }
   
  .color-rectangle {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 5px;
    vertical-align: middle;
  }

}

.team-card {
  background: white;
  border: 1px solid $light-border;
  border-radius: 4px;

  border-top: 1px solid $light-border;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 20px;

  cursor: pointer;

  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    border-bottom: 7px;
    position: absolute;
    top: 18px;
    .text {
      margin-left: 5px;
    }
  }
  .issue-count-heading {
    margin-top: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    color: $darkGrey;

    .right-align {
      border-right: 23px transparent solid;
      text-align: right;
    }
  }

  .issue-count {
    font-style: normal;
    font-weight: 600;

    display: flex;
    font-size: 20px;
    line-height: 26px;
    color: #030D1D;

    .right-align {
      text-align: right;
    }

    .team-time {
      @media (max-width: 400px), (min-width: 575px) and (max-width: 1580px) {
        white-space: normal;
        font-size: 15px;
      }
    }
    .team-info {
      display: inline-flex;
      margin-right: 12px;
      text-align: right;

      .team-icon {
        float: left;
        line-height: 20px;
        margin-right: 4px;
      }

      .team-text {
        float: left;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        cursor: pointer;
  
        align-items: center;
        margin-right: 12px;
  
        color: $darkest-gray;
      }
  

    }
    .small-screen-font {
      line-height: 26px;

      .time {
        font-size: 12px;
        line-height: 16px;
        margin-left: 2px;
      }

      .right-margin {
        margin-right: 16px;
      }
    }
  }

  .large-font {
    font-size: 36px;
    line-height: 47px;
    @media (max-width: 1000px) {
      font-size: 20px;
      white-space: normal;
    }
  }
  
  .nowrap {
    white-space: nowrap;

    @media screen and (max-width: 1300px) {
      white-space: normal;
    }
  }
  .nowrap-always {
    white-space: nowrap;
  }

}

.info-card {
  margin-top: 30px;
  padding: 0 0 20px 40px;
  cursor: default;

  .members-number {
    margin-right: 8px;
  }

  .teams-members {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    vertical-align: super;
    display: flex;

    .circle {
      height: 24px;
      width: 24px;
      margin-right: 4px;
      background-color: $circle-background;
      border-radius: 50%;
      float: left;
  
      .initials {
        font-size: 10px;
        line-height: 13px;
        margin: 6px;
        color: $darkest-gray;
      }
    }
    .member-name {
      cursor: pointer;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
    }
  }

  .grid-divider {
    position: relative;
  }

  .grid-divider > [class*="col"]:nth-child(n + 2):after {
    content: "";
    background-color: $grey;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0px;
    margin-left: -40px;

    @media (max-width: 1242px) {
      display: none;
      width: 0%;
      height: 100px;
      left: 0;
      top: -4px; // quickfix to center line between columns
    }
    @media (min-width: 1100px) {
      width: 1px;
      height: auto;
      left: -1px; // quickfix to hide divider on left side
    }
  }
}


// global
.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

// graph tooltip 
.custom-tooltip {
  display: inline-block;
}

.tooltiptext {
  background: $darkest-gray;
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
}

.xFullTeamLabelTick {
  font-size: 12px;
  font-weight: 600;
  color: $darkest-gray;
  @media (max-width: 999px) {
    display: none;
  }
  @media (min-width: 1000px) and (max-width: 1100px) {
    font-size: 10px;
  }
}

.xSimpleTeamLabelTick {
  @media (max-width: 600px), (min-width: 1000px) and (max-width: 1300px) {
    display: none;
  }
}

.xTypeLabelTick {
  font-size: 12px;
  color: $darkest-gray;
  word-wrap: break-word;

  @media (min-width: 994px) and (max-width: 1300px){
    font-size: 8px;
  }
  @media (max-width: 720px ), (min-width: 1301px) and (max-width: 1499px){
    font-size: 10px;
  }
}