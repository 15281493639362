@import "global/constants";

.power-issues {

    @media only screen and (max-width: 992px) {
        margin: 2%;
    }

    @media (min-width: 933px) {
        .tutorial {
            min-height: 212px;
            flex-direction: column;
            justify-content: space-evenly;
            width: 100%;
            background: $table-header-color;
            border: 1px solid $light-border;
            box-sizing: border-box;
            padding: 20px 20px 20px 20px;

            .back-icon {
                margin-left: auto;
                width: 16px;
                height: 16px;
                color: $grey;
            }

            table {
                td {
                    text-align: center;
                    vertical-align: middle;
                }
            }
        }

        .div-hidden {
            display: none;
        }
    }

    @media (max-width: 933px) {
        .tutorial {
            display: none;
        }
    }

    .breadcrumb {
      padding: 0.75rem 0;
      background-color: transparent;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      margin-top: 11px;
      .breadcrumb-item a {
        color: $darkGrey;
      }
      .active a{
        color: $black;
      }
      .breadcrumb-item + .breadcrumb-item::before {
        content: ">";
      }
    }

    .heading-div {
        display: inline-block;
        width: 100%;

        .heading {
            float: left;
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 47px;
            color: $darkest-gray;
        }

        div, svg {
            display: inline;
        }

        .tutorial-info {
            float: left;
            margin-top: 15px;
            margin-left: 10px;
            .tutorial-text {
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 24px;

                .tutorial-icon {
                    color: $header-border-color;
                    cursor: pointer;
                }

                .tutorial-icon:hover {
                    color: $darkest-gray;
                }
            }

        }
    }

    .active-toggle {
        float: right;
        margin-bottom: 5px;
    }

    .tutorial-info {
      float: right;
      margin-bottom: 5px;

        .tutorial-text {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;

            .tutorial-icon {
                color: $header-border-color;
                cursor: pointer;
            }

            .tutorial-icon:hover {
                color: $darkest-gray;
            }
        }
    }

    .power-issues-div {
        width: 100%;
        .dt-align-right {
            text-align: right;
        }

        .dt-align-left {
            text-align: left;
        }

        .dt-align-center {
            text-align: center;
        }

        .table{
            th {
                background-color: $table-header-color;
                border-top: 1px solid $grey;
                border-right: 1px solid $grey;

                &:first-child {
                    border-left: 1px solid $header-border-color;
                }
            }

            tbody {
                .notifications {
                    .notification-level, .snoozed-info, .notification-info {
                        display: inline;
                    }
                }

                .snooze-icon {
                    width: 16px;
                }

                .snooze-icon, .seen-icon, .info-icon, .comment-icon, .resolve-icon {
                    cursor: pointer;
                }

                .snooze-icon.medium, .comment-icon.medium {
                    color: $darkGrey;
                }

                .snooze-icon.medium:hover, .disabled-icon-snooze.light:hover, .disabled-icon:hover, .comment-icon.light:hover, .comment-icon.medium:hover, .resolve-icon:hover {
                    color: $background-dark;
                    fill: $background-dark;
                }

                .disabled-icon-snooze.light, .comment-icon.light, .resolve-icon {
                    color: $grey;
                }

                .disabled-icon>path {
                    color: $disabled-snooze-color;
                    fill: $disabled-snooze-color;
                }

                .disabled-icon:hover>path {
                    fill: $background-dark;
                }

                tr {
                    height: 40px;
                    border: 1px solid $light-border;
                    border-bottom: none;
                    box-sizing: border-box;

                    &:nth-of-type(odd) {
                    background: $white;
                    }

                    &:nth-of-type(even) {
                    background: $background-light;
                    }
                }

                tr:hover {
                    background-color: $table-header-color;
                    box-shadow: 0px 4px 12px -4px $light-border;

                    td {
                        border-top-color: $header-border-color;
                        border-bottom-color: $header-border-color;

                        &:first-child {
                            border-left-color: $header-border-color;
                        }

                        &:last-child {
                            border-right-color: $header-border-color;
                        }
                    }
                }
            }
            .resolvedContainer {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
        }
    }
}


.tutorial-modal-power {
  margin-bottom: 0px;

  .highlightModalText {
    color: $blue;
    font-size: 15px;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
}

  .modal-dialog {
      max-width: 728px;
      .modal-content {
          width: 728px;

          .vl-container {
              position: relative;
              height: 24px;
          }

          .vl {
              position: absolute;
              bottom: 12px;
              border-left: 1px solid $darkGrey;
              height: 24px;
              width: 0px;
              margin-left: 50%;
          }

          svg {
            margin: auto;
          }

          .modalText {
              margin-bottom: 0px;
          }

          .modalStepText {
              font-weight: 700;
          }

          .form-check {
            margin-top: 5px;
            text-align: center;
            margin-right: 80px;
            .form-check-input {
                margin-top: .1rem;
            }
            .checkBoxText {
                font-size: 11px;
                font-weight: 500;
            }
        }
      }
  }
}

.tutorial-modal-unauthorized {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;

    .modal-dialog {
        max-width: 1080px;

        .unauthorized-modal-container {
            padding: 48px 32px 24px;
            margin-bottom: 0;

            .tutorial-container {
                display: flex;
                flex-wrap: wrap;
                gap: 24px;

                @media (max-width: 992px) {
                    flex-direction: column;
                }

                .tutorial-section-container {
                    flex-grow: 1;
                    flex-basis: 0;

                    p {
                        margin-bottom: 0;
                    }

                    .step-container {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        margin-bottom: 24px;

                        svg {
                            flex: none;
                        }

                        &.top-step {
                            margin-bottom: 12px;
                        }
                    }

                    .step-subtitle {
                        font-weight: bold;
                        text-transform: uppercase;
                        margin-bottom: 20px;
                    }

                    .vertical-line {
                        flex: none;
                        width: 1px;
                        height: 24px;
                        background-color: $darkGrey;
                        margin: 0 24px 12px;
                    }

                    .angle-line {
                        flex: none;
                        border-left: 1px solid $darkGrey;
                        border-bottom: 1px solid $darkGrey;
                        width: 12px;
                        height: 12px;
                    }
                }
            }

            .modal-action {
                width: fit-content;
                margin: auto;

                .modal-confirm-button {
                    min-width: 240px;
                    margin-top: 12px;
                    margin-bottom: 6px;
                }

                .checkbox-input-label {
                    display: flex;
                    gap: 4px;
                }
            }
        }
    }
}

.modal-open .power-issues {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}