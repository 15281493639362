@import "global/constants";

.modal {
    .add-reason-modal {
        .modal-content {
            width: 300px;
            margin: auto;

            .modal-container {
                margin-bottom: 0;
                border: none;
                padding-bottom: 80px;

                .warning-text {
                    font-style: italic;
                    font-size: 10px;
                    line-height: 13px;
                    color: $red;
                    margin-top: 12px;
                }

                .action-label {
                    display: inline-block;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    margin-bottom: 4px;
                    margin-top: 20px;
                }

                .reason-box {
                    width: 100%;
                    height: 100px;
                    min-height: 30px;
                    max-height: 100px;
                    border: 1px solid $light-border;
                    box-sizing: border-box;
                    border-radius: 4px;
                    font-size: 12px;
                    line-height: 16px;
                    resize: none;
                }

                .character-count {
                    position: absolute;
                    right: 24px;
                    bottom: 120px;
                    font-weight: 500;
                    font-size: 8px;
                    line-height: 10px;
                    background: $white;
                }

                .form-check {
                    .form-check-input {
                        margin-top: .1rem;
                    }

                    .checkbox-label {
                        font-size: 12px;
                        color: $darkGrey;
                    }
                }

                .buttons {
                    position: absolute;
                    bottom: 20px;
                    right: 20px;

                    .cancel-button, .add-button {
                        display: inline;
                        height: 36px;
                        width: 106px;
                        text-transform: uppercase;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 14px;
                        padding: 0px;

                        &:disabled {
                            color: $white;
                        }
                    }

                    .cancel-button {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
