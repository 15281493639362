@import "global/constants";

.stolen-procedure {
  padding-bottom: 68px;

  @media only screen and (max-width: 992px) {
    margin: 2%;
  }

  .navigation-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .breadcrumb {
      padding: 0.75rem 0;
      background-color: transparent;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      margin-top: 11px;
      margin-bottom: calc(16px - 0.75rem);

      .breadcrumb-item a {
        color: $darkGrey;
      }

      .active a {
        color: $black;
      }

      .breadcrumb-item + .breadcrumb-item::before {
        content: ">";
      }
    }

    .back-navigation {
      font-size: 11px;
      line-height: 13px;
      font-weight: 900;
      color: $darkGrey;
      margin-top: 11px;
      margin-bottom: calc(16px - 0.75rem);
    }
  }

  .tutorial-container {
    background: $white;
    border: 1px solid $grey;
    margin: 0;
    padding: 32px 6px 22px 6px;

    .heading {
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 47px;
      color: $darkest-gray;
    }

    .vl-container {
      position: relative;
    }

    .vl {
      position: absolute;
      bottom: 2px;
      left: 34px;
      border-left: 1px solid $darkGrey;
      width: 0;
    }

    .vll {
      border-left: 1px solid $darkGrey;
      border-bottom: 1px solid $darkGrey;
      height: 12px;
      width: 12px;
      margin-left: 20%;
    }

    svg {
      margin: auto;
    }

    .step-text, .modal-text {
      margin-bottom: 0;
      color: $background-dark;
    }

    .step-text {
      font-size: 11px;
      font-weight: bold;
      line-height: 14px;
    }

    .modal-text {
      font-size: 12px;
      line-height: 16px;
    }

    .vll-container {
      vertical-align: top;
      padding-top: 12px;
    }

    .action-text {
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: $blue;
      font-weight: 600;
      text-decoration: underline;
      margin-top: 10px;
      margin-bottom: 24px;
      cursor: pointer;
    }

    .form-check {
      margin-top: 5px;
      text-align: center;
      margin-right: 80px;

      .form-check-input {
        margin-top: .1rem;
      }

      .checkbox-text {
        font-size: 11px;
        font-weight: 500;
      }
    }
  }
}
