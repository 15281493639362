@import "global/constants";

.not-found {
  width: 100vw;
  min-height: 100vh;
  min-width: 100vw;
  height:100vh;
  background: url("../../files/404/404.svg");
  height: 100%; 

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;


  @media (min-height: 1501px) {
    .container {
      padding-top: 1000px;
    }
  }

  @media (min-height: 1201px) and (max-height: 1500px) {
    .container {
      padding-top: 720px;
    }
  }

  @media (min-height: 741px) and (max-height: 1200px) {
    .container {
      padding-top: 520px;
    }
  }

  @media (min-height: 600px) and (max-height: 740px) {
    .container {
      padding-top: 350px;
    }
  }


  @media (max-height: 599px) {
    .container {
      padding-top: 280px;
    }
  }

  .container {
    text-align: center;

    .header {
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 47px;
      text-align: center;
      letter-spacing: 0.02em; 
      color: white;
      background-color: transparent;
    }

    .description {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: white;
    }

    .btn {
      width: 138px;
      height: 36px;
      font-size: 11px;
      font-style: normal;
      font-weight: bold;
      margin-top: 16px;
      padding-top: 8px;
      padding-right: 16px;
      padding-bottom: 8px;
      padding-left: 16px; 
      background: $green;
      border-radius: 2px;
      border-color: transparent;
      color: white;
    }
  }
}