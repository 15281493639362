@import "global/constants";

.recipients-tooltip {
    font-size: 12px;
    line-height: 16px;
    color: $darkest-gray;
    word-break: break-all;

    .arrow-box {
        position: relative;
        max-width: 230px;
        max-height: 400px;
        padding: 10px;
        overflow: auto;
        background: $white;
        border: 1px solid $light-border;
        border-radius: 4px;
        box-shadow: 0 8px 24px -8px rgba(3, 13, 29, 0.15);

        &:after,
        &:before {
            right: 100%;
            top: 50%;
            border: solid transparent;
            content: "";
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-color: rgba(255, 255, 255, 0);
            border-right-color: $white;
            border-width: 10px;
            margin-top: -10px;
        }

        &:before {
            border-color: rgba(226, 229, 233, 0);
            border-right-color: $light-border;
            border-width: 11px;
            margin-top: -11px;
        }
    }

    .email-field {
        font-weight: 500;
        background-color: $table-header-color;
        margin-top: 4px;
        width: fit-content;
        border-radius: 2px;
        padding: 1px 4px;
    }
}
