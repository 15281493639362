@import "global/constants";

.assignmentDetails {
  .breadcrumb {
    padding: 0.75rem 0;
    background-color: transparent;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin-top: 11px;
    height: fit-content;
    margin-bottom: 0px;
    .breadcrumb-item a {
      color: $darkGrey;
    }
    .active a{
      color: $black;
    }
    .breadcrumb-item + .breadcrumb-item::before {
      content: ">";
    }
  }
  .back-link {
    margin-left: auto;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 900;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: $darkGrey;
    height: 15px;
}
  .expand-button {
    margin-left: auto;
    margin-right: 1%;
    color: $darkGrey;
    width: 21px;
    height: 37px;
  }
  .orderHeading {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 47px;
    margin-bottom: 30px;

    div, svg {
      display: inline;
    }
  }
  .trailerSettingsDetails {
    margin-top: 0px;
  }

  .snooze-icon {
    width: 16px;
  }

  .snooze-icon, .seen-icon, .info-icon, .comment-icon, .resolve-icon {
    cursor: pointer;
  }

  .snooze-icon.medium, .comment-icon.medium {
    color: $darkGrey;
  }

  .snooze-icon.medium:hover, .disabled-icon-snooze.light:hover, .disabled-icon:hover, .comment-icon.light:hover, .comment-icon.medium:hover, .resolve-icon:hover {
    color: $background-dark;
    fill: $background-dark;
  }

  .disabled-icon-snooze.light, .comment-icon.light, .resolve-icon {
    color: $grey;
  }

  .disabled-icon>path {
    color: $disabled-snooze-color;
    fill: $disabled-snooze-color;
  }

  .disabled-icon:hover>path {
    fill: $background-dark;
  }
  
  .arrow-icon {
    width: 15px;
    height: 30px;
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  table {
    tbody {
      .manager-notified, .operations-notified {
        .notification-level, .notification-info {
          display: inline;
        }
      }
    }
  }
}
