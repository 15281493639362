@import "global/constants";

.resolved-tooltip {
    .arrow-box {
        box-shadow: 0px 8px 24px -8px rgba(3, 13, 29, 0.15);
        position: relative;
        background: $white;
        border: 1px solid $light-border;
        width: 248px;
        border-radius: 4px;
        padding: 10px;

        .heading {
            text-align: left;
            padding-left: 0px;
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            color: $darkest-gray;
            margin-bottom: 0px;
        }

        .divider {
            margin-top: 12px;
            margin-bottom: 6px;
        }

        .info-label-heading {
            display: inline;
            font-weight: bold;
            margin-bottom: 0px;
            line-height: 16px;
            color: $darkest-gray;
            font-size: 12px;
        }

        .info-label {
            display: block;
            font-weight: normal;
            line-height: 16px;
            color: $darkest-gray;
            font-size: 12px;
            margin-bottom: 0px;
            word-wrap: break-word;
            max-height: 100px;
            overflow-y: auto;
        }
    }

    .arrow-box:after, .arrow-box:before {
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        transform: rotate(270deg);
    }
    
    .arrow-box:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: $white;
        border-width: 9px;
        bottom: -18px;
        left: 214px;
    }
    
    .arrow-box:before {
        border-color: rgba(226, 229, 233, 0);
        border-right-color: $light-border;
        border-width: 10px;
        bottom: -20px;
        left: 213px;
    }   
}
