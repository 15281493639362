@import "global/constants";

.search-bar {
    height: inherit;
    display: inline-block;
    label {
        margin: 0 20px;

        svg {
            position: relative;
            top: 5px;
            width: 23px !important;
            height: 23px;
        }
    }

    input[type = "text"] {
        float: left;
        width: 300px;
        background-color: transparent;
        padding: 5px 0;
        margin: 15px 0;
        padding-right: 60px;
        padding-left: 10px;
        height: 32px;
        outline: none;
        border: 1px solid $header-border-color;
        box-sizing: border-box;
        border-radius: 4px;
    }
    
    .btn:focus {
        outline: none;
        box-shadow: none;
    }
    
    .search-buttons {
        float: left;
        top: 14px;
        width: 60px;
        position: absolute;
        left: 250px;

        .by-div {
            position: absolute;
            left: 80px;
            top: 5px;
        }
        select {
            width: 91px;
            height: 32px;
            margin-right: 5px;
            cursor: pointer;
        
            background: white;
            border: 1px solid $light-border;
            box-sizing: border-box;
            border-radius: 4px;
            padding-left: 20px;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
             -o-appearance: none;
              appearance: none;
        }
          
        
        .select-div {
            text-align: right;
            position: absolute;
            top: 1px;
            left: 115px;

            .select-wrapper {
            width: 91px;
            height: 32px;
            }
            
            .select-text {
              margin-left: 1%;
              display: inline-block;
            }
        
            select::-ms-expand {
                display: none;
            }
        }

        

        .clear-button {
            width: 14px;
            color: $light-border;
            svg {
                vertical-align: baseline;
                height: 14px;
                width: 12px;
            }
            :hover {
                color: black;
            }
        }
        .search-button {
            width: 14px;
            position: absolute;
            right: 5px;
            color: $light-border;
            svg {
                vertical-align: baseline;
                height: 14px;
                width: 12px;
            }
            &.active {
                color: black;
            }
        }
        .vertical-line {
            border-left:0.5px solid $light-border; 
            margin-left: 7px;
        }
    }
    
}
