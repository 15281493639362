@import "global/constants";

.notifications-management {
    padding-top: 8px;
    color: $darkest-gray;
    padding-bottom: 20px;

    .header {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        white-space: nowrap;
        background-color: transparent;
    }

    .expand {
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        
        .expand-arrow {
            margin-left: 5px;
        }
    }
}
