@import "global/constants";

.text-tooltip {
    .text-arrow-box {
        box-shadow: 0px 8px 24px -8px rgba(3, 13, 29, 0.15);
        position: relative;
        background-color: $background-dark;
        border: 1px solid $background-dark;
        height: fit-content;
        width: fit-content;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px;    

        .text {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $white;
        }

        .last {
            margin-bottom: 0;
        }
    }

    .text-arrow-box:after, .text-arrow-box:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    
    .text-arrow-box:after {
        border-color: rgba(23, 36, 55, 0);
        border-bottom-color: $background-dark;
        border-width: 10px;
        margin-left: -10px;
    }

    .text-arrow-box:before {
        border-color: rgba(23, 36, 55, 0);
        border-bottom-color: $background-dark;
        border-width: 11px;
        margin-left: -11px;
    }
}