@import "global/constants";

.modal {
    .add-group-modal {
        .modal-content {
            width: 300px;
            margin: auto;

            .modal-container {
                margin-bottom: 0;
                border: none;
                padding-bottom: 80px;

                .row {
                    padding: 0 10px;

                    .half-form {
                        padding: 0 5px;
                    }
                }

                .action-label {
                    display: inline-block;
                    font-style: normal;
                    font-size: 12px;
                    line-height: 16px;
                    margin-bottom: 4px;
                }

                .group-name-input {
                    width: 258px;
                    background-color: transparent;
                    padding: 5px 10px;
                    font-size: 12px;
                    height: 32px;
                    outline: none;
                    border: 1px solid $header-border-color;
                    box-sizing: border-box;
                    border-radius: 4px;
                }

                .form-check {
                    margin-top: 16px;

                    .form-check-input {
                        margin-top: .15rem;
                    }

                    .checkbox-label {
                        font-size: 12px;
                        color: $darkGrey;
                    }
                }

                .buttons {
                    position: absolute;
                    bottom: 20px;
                    right: 20px;

                    .cancel-button, .add-button {
                        display: inline;
                        height: 36px;
                        width: 106px;
                        text-transform: uppercase;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 14px;
                        padding: 0px;

                        &:disabled {
                            color: $white;
                        }
                    }

                    .cancel-button {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
