@import "global/constants";

.banner {
  display: flex;
  align-items: center;
  gap: 15px;

  padding: 16px 40px;

  font-weight: 500;
  font-size: 15px;

  &.warning {
    background-color: $reefer-warning-200;
    border-top: 1px solid $reefer-warning-300;
    border-bottom: 1px solid $reefer-warning-300;

    .icon {
      height: 28px;
      color: $reefer-warning-500;
    }
  }
}
