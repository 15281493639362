@import "global/constants.scss";

.stats-header {
  border-top: 4px solid $background-dark;

  .time {
    font-size: 16px;
  }

  .issue-count {
    .table-open-button {
      border: none;
      background-color: white;
    }

    .issue-link-icon {
      color: $grey;
      width: 16px;
    }
  }
}

