@import "global/constants";

.no-results {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .image-container {
        width: 612px;
        height: 54.3vw;
        max-height: 565px;
        background: url("../../files/no-results/search.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-origin: content-box;
        margin-right: 11px;
        margin-left: 11px;
        padding: 50px;
    }

    .text-container {
        width: 390px;
        color: $background-dark;
        margin: 11px;

        .logo-container {
          width: 118px;
          height: 40px;
          background: url("../../files/logo/logo.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }

        .title {
          font-weight: 900;
          font-size: 36px;
          line-height: 47px;
          letter-spacing: 0.02em;
        }

        .description {
          font-size: 20px;
          line-height: 26px;

          .query-text {
            max-width: 390px;
            max-height: 5em;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            font-weight: bold;
            vertical-align: bottom;
          }
        }
    }
}
