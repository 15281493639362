.day-picker {
    .select-day-picker__control {
        height: 32px;
        min-height: 32px;

        .select-day-picker__value-container {
            height: 32px;

            .select-day-picker__single-value, .select-day-picker__placeholder {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
            }
        }

        .select-day-picker__indicators {
            height: 32px;
            .select-day-picker__indicator-separator {
                display: none;
            }
        }
    }

    .select-day-picker__menu {
        .select-day-picker__menu-list {
            height: 128px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
        }
    }
}
