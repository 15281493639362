@import "global/constants";

.modal {
    width: 480px;
    height: 654px;
  
    .title {
      margin-bottom: 20px;
    }
  
    .modalText {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $darkest-gray;

      .italic {
        font-style: italic;
      }
    }
  
    .checkBoxText {
      font-size: 12px;
      line-height: 16px;
      color: $black;

      .bold {
        font-style: bold;
      }
    }
  
    .center {
      text-align: center;
    }
  
    .btn-continue {
      display: block;
      background-color: $green;
      color: $white;
      font-style: normal;
      font-size: 11px;
      font-weight: 700;
      line-height: 14px;
      align-items: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      width: 106px;
      height: 36px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 2px;
    }
  
    .btn-continue:disabled {
      background-color: $header-border-color;
    }
}

