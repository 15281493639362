@import "global/constants.scss";

.paging {
  margin-top: 3%;
  margin-bottom: 2%;
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  width: 100%;
  color: $darkest-gray;

  .icon {
    color: $darkGrey;
    cursor: pointer;
  }
  
  .showing-label-div {
    @media screen and (max-width: 1199px) {
      width: 17.5%;
    }
    @media screen and (min-width: 1200px) {
      width: 25%;
    }
    text-align: left;
  }
  
  .page-number-div {
    text-align: center;
    @media screen and (max-width: 1199px) {
      width: 65%;
    }
    @media screen and (min-width: 1200px) {
      width: 50%;
    }
    @media screen and (min-width: 600px) {
      white-space: nowrap;
    }

    > * {
      margin-left: 8px;
      margin-right: 9px;
    }

    .page-number-label {
      > * {
        margin-left: 5px;
        margin-right: 5px;
      }
      input {
        width: 40px;
        height: 32px;
        background: white;
        border: 1px solid $light-border;
        box-sizing: border-box;
        border-radius: 4px;
        align-items: center;
        text-align: center;

        color: $darkest-gray;
      }
      .inputError {
        background: $light-input-background-color;
        border: 1px solid $red;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
    
  }

  select {
    width: 60px;
    height: 32px;
    margin-right: 5px;
    cursor: pointer;

    background: white;
    border: 1px solid $light-border;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
     -o-appearance: none;
      appearance: none;
  }
  
  .select-div {
    position: relative;	
    @media screen and (max-width: 1199px) {
      width: 17.5%;
    }
    @media screen and (min-width: 1200px) {
      width: 25%;
    }
    text-align: right;

    .select-wrapper:after {
        font-family: FontAwesome;
        content: '\f107';
        position: absolute;
        top: 9px;
        right: 58px;
        font-size: 12px;
        color: $darkGrey;
        pointer-events: none;
        display: inline-block;
    }
  
    .select-text {
      margin-left: 1%;
      display: inline-block;
    }

    select::-ms-expand {
        display: none;
    }
  }
  

}