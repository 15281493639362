@import "global/constants.scss";

.input-container {
    position: relative;

    .input {
        font-size: 12px;
        line-height: 16px;
        padding: 8px;
        border: 1px solid $grey;
        border-radius: 4px;

        &::placeholder {
            color: $darkGrey;
            font-weight: 500;
        }

        &:focus {
            outline: none;
        }

        &.with-left-icon {
            padding-left: 30px;
        }
    }

    .left-icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
    }
}
