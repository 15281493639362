@import "global/constants.scss";

.events {
    .breadcrumb {
        padding: 0.75rem 0;
        background-color: transparent;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-top: 11px;

        .breadcrumb-item a {
            color: $darkGrey;
        }

        .active a {
            color: $black;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: ">";
        }
    }

    .heading-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 24px;

        .heading {
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 47px;
            color: $darkest-gray;
            margin-bottom: 20px;
        }
    }

    .event-info {
        display: flex;
        align-items: center;
        gap: 4px;

        .info-icon {
            cursor: pointer;
        }
    }
}
