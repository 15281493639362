@import "global/constants";

.grid-list {
    width: 100%;
    color: $darkest-gray;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;

    .header-row, .data-row {
        background-color: $white;
        margin-left: 0;
        margin-right: 0;

        .cell {
            padding: 8px 0 8px 20px;
            word-wrap: break-word;
        }
    }

    .header-row {
        font-weight: 700;

        .cell {
            padding-top: 14px;
            padding-bottom: 0;
        }

        .clickable {
            cursor: pointer;
        }
    }

    .data-row {
        border-bottom: 1px solid $table-header-color;
    }

    .sort-icon {
        display: inline-block;
        position: relative;
        width: 10px;
        margin-left: 4px;

        .asc {
            position: absolute;
            bottom: -1px;
        }

        .desc {
            position: absolute;
            bottom: -1px;
        }

        path {
            fill: $grey;
        }

        .active {
            background-color: initial;

            path {
                fill: $background-dark;
            }
        }
    }
}
