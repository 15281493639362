@import "global/constants.scss";

.all-trailers {
  .breadcrumb {
    padding: 0.75rem 0;
    background-color: transparent;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin-top: 11px;

    .breadcrumb-item a {
      color: $darkGrey;
    }

    .active a {
      color: $black;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      content: ">";
    }
  }

  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 47px;
    color: $darkest-gray;
    margin-bottom: 20px;
  }
}
