@import "global/constants.scss";

.events-filter {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;

    .filter-input {
        width: 120px;
    }

    .issue-type-select {
        width: 150px;
    }

    .event-type-select {
        width: 170px;
    }

    .user-select {
        width: 160px;
    }
}
